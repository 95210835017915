// Components/RasaChat.js
import React, { useEffect } from "react";

const RasaChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/rasa-webchat/lib/index.js";
    script.async = true;
    script.onload = () => {
      window.WebChat.default(
        {
          initPayload: "/greet",
          customData: { language: "en" },
          socketUrl: "http://192.168.1.203:5005/",
        },
        null
      );
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Nettoyage pour éviter les duplications.
    };
  }, []);

  return <div />;
};

export default RasaChat;
