import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Paper, Autocomplete, TextField, MenuItem, InputLabel } from '@mui/material';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMediaQuery } from '@mui/material';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },

];
const variableOptions = [
  { label: "" },
  { label: "" },
  { label: "" },

];
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 

function PredictionsComponent() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [tabValue, setTabValue] = useState('1');
    const [period, setPeriod] = useState('');
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedAnnee, setSelectedAnnee] = useState(null);
    const [SelectedVariable, setSelectedVariable] = useState(null);



    const handlePeriodChange = (selectedPeriod) => {
      setPeriod(selectedPeriod);
    };
  
    // Gestion du changement de trimestre
    const handleQuarterChange = (event) => {
      setSelectedQuarter(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleAnneeChange = (event, value) => {
      setSelectedAnnee(value?.label || null);
    };
    const handleVariableChange = (event, value) => {
      setSelectedVariable(value?.label || null);
    };
    const isXs = useMediaQuery('(max-width:600px)');

    return (
        <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Evolution des marchés', 'Prévisions de production'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
  
            <TabPanel value="1">
            <Box sx={{ marginBottom: 3 }}>
        <FormLabel component="legend">Sélectionnez la période</FormLabel>
        <Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
        <Autocomplete
          options={anneeOptions}
          onChange={handleAnneeChange}
          renderInput={(params) => <TextField {...params} label="Targets" color="success"/>}
          sx={{ width: { xs: 120, sm: 150, md:200 }, marginLeft: 2 }}
        />
        <Autocomplete
          options={variableOptions}
          onChange={handleVariableChange}
          renderInput={(params) => <TextField {...params} label="Variables" color="success"/>}
          sx={{ width: { xs: 120, sm: 150, md:200 }, marginLeft: 2 }}
        />
        </Box>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <h3>Données sélectionnées</h3>
        {/* <p>Période : {period ? (period === 'week' ? 'Cette Semaine' : period === 'month' ? 'Ce Mois' : 'Cette Année') : 'Aucune'}</p>
        <p>Trimestre : {selectedQuarter || 'Aucun'}</p> */}
      </Box>
            </TabPanel>
  
            <TabPanel value="2">           
                </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  }
  
  export default RoleCheck(['Commercial'])(PredictionsComponent);
