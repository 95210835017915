import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { AppBar, Toolbar, IconButton, Typography, Modal, Grid, Box, Paper, Autocomplete, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import CustomCard from '../CustomCard';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import { baseUrl, commercialTime } from '../../api/auth_constant';
import { commercialUrl, commercialUrlAn } from '../../api/auth_constant';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';
import TypeChart from '../TypeChart';
import { MapChart } from './DashBoardCommercialUtils/MapChart';
import { MapChartArticle } from './DashBoardCommercialUtils/MapChartArticle';
import MapChartClient from './DashBoardSavUtils/MapChartClient';
import BarChartRender from './DashBoardCommercialUtils/BarChartRender';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LinkIcon from '@mui/icons-material/Link';
import { useMediaQuery } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import FlecheComponent from '../FlecheComponent';
import { useData } from '../DataContext'; 
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },

];
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 
const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];
function DashboardCommercial() {
  const { commercialData, setCommercialData } = useData();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [totalVentes, setTotalVentes] = useState(0);
    const [tracteursCount, setTracteursCount] = useState(0);
    const [remorquesCount, setRemorquesCount] = useState(0);
    const [ventePuissance, setVentePuissance] = useState([]);
    const [venteMarque, setVenteMarque] = useState([]);
    const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
    const [kitsCount, setKitsCount] = useState(0);
    const [autresCount, setAutres] = useState(0);
    const [typeChartData, setTypeChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [tabValue, setTabValue] = useState('1');
    const [showMore, setShowMore] = useState(false);
    const [selectedAnnee, setSelectedAnnee] = useState(2024);
    const [chartData, setChartData] = useState({
      labels: [],
      data: [],
    });
    useEffect(() => {
        fetchData1(commercialTime);
    });
    useEffect(() => {
      if (selectedAnnee) {
        fetchData2(selectedAnnee); // Utilisez `selectedAnnee` ici, et non `commercialTime`
      }
    }, [selectedAnnee]);    

    useEffect(() => {
      fetchDataCard(commercialUrl);
  });

    const fetchDataCard = async (url) => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const response = await fetch(commercialUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: currentYear,
            mois:3
          }),
      });
         if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log('API Response:', result);
        const data = result.data?.[0];
        if (data) {
          setTracteursCount(data["nombre_tracteurs_mois"]);
          setRemorquesCount(data["nombre_remorques_mois"]);
          setAutres(data["nombre_autres_mois"]);
          setKitsCount(data["nombre_kits_mois"]);
        } else {
          console.error('No data found in response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchData1 = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
    
        const response = await fetch(commercialTime, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: 2024,
            mois: 3,
          }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log("data", data);
    
        if (data && data.data && Array.isArray(data.data) && data.data.length > 0) {
          const venteData = data.data[0]; // Accès au premier élément du tableau
    
          // Formatage des données pour les ventes par puissance
          const formattedDataPuissance = venteData.Ventes_par_Puissance
            ?.filter((item) => item.Puissance && item.Puissance !== '' && item.Puissance !== null) // Filtrer les données valides
            .map((item) => ({
              name: item.Puissance,
              VenteParPuissance: item.Nombre_de_vente || 0,
            }))
            .sort((a, b) => b.VenteParPuissance - a.VenteParPuissance); // Tri par nombre de ventes décroissant
    
          // Formatage des données pour les ventes par marque
          const formattedDataMarque = venteData.Ventes_par_Marque
            ?.filter((item) => item.Marque && item.Marque !== '' && item.Marque !== null) // Filtrer les données valides
            .map((item) => ({
              name: item.Marque,
              VenteParMarque: item.Nombre_de_vente || 0,
            }))
            .sort((a, b) => b.VenteParMarque - a.VenteParMarque); // Tri par nombre de ventes décroissant
    
          // Mise à jour des états
          setVentePuissance(formattedDataPuissance || []);
          setVenteMarque(formattedDataMarque || []);
        } else {
          console.error('Données inattendues ou vides');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    
    const fetchData2 = async () => {
      try {
        const response = await fetch(commercialUrlAn, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: Number(selectedAnnee), // Modifier l'année si nécessaire
          }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
    
        if (data && data.data && data.data.Ventes_par_Mois) {
          // Noms des mois pour affichage
          const moisLabels = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
          ];
    
          // Formatage des données pour le graphique
          const formattedData = data.data.Ventes_par_Mois.map((item) => ({
            month: moisLabels[item.Mois - 1], // Convertir le numéro du mois en nom
            Production: item.Nombre_de_vente_mois || 0, // Valeur par défaut si Nombre_de_vente est manquant
          }));
    
          // Mise à jour des données du graphique
          setChartData(formattedData);
        } else {
          console.error('Données inattendues ou vides');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
      
    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleVagueChange = (event, newValue) => {
      setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
    };

    const handleShowMore = () => {
      setShowMore(!showMore);
    };
    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleAnneeChange = (event, value) => {
      setSelectedAnnee(value?.label || null);
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const isXs = useMediaQuery('(max-width:600px)');
    const isXl = useMediaQuery('(min-width:1200px)');
    return (
        <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Ventes', 'Cartes'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
  
            <TabPanel value="1">
            <div style={{ textAlign: 'center'}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Tracteurs" value={`${tracteursCount}`} color="#ffff" icon={<AgricultureIcon />} svalue={'+500'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Remorques" value={`${remorquesCount}`} color="#ffff" icon={<RvHookupIcon />} svalue={'+350'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Kits" value={`${kitsCount}`} color="#ffff" icon={<DeveloperBoardIcon />} svalue={'+300'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Autres équipements" value={`${autresCount}`} color="#ffff" icon={<LinkIcon />} svalue={'+250'}/>
                </Grid>
              </Grid>
              {selectedVague === "" && (
                 <>
              <Grid container spacing={3}>
              <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 },
                      height: { xs: 380, sm: 400, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  >   
            
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par puissance</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={250} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={30} minPointSize={60}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 },
                      height: { xs: 380, sm: 300, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >   
                <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par marque</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={200} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={30} minPointSize={60}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de vente par mois</Typography>
                  <Box mb={3}>
                  <Autocomplete
                 options={anneeOptions}
                  onChange={handleAnneeChange}
                  renderInput={(params) => <TextField {...params} label="Année" color="success" size="small" />}
                  sx={{ width: { xs: 120, sm: 150 }, marginLeft: 2 }}
        />
                  </Box>
                  <ResponsiveContainer
  width="100%"
  sx={{
    maxWidth: {
      xs: '100%',
      sm: '100%',
      md: '800px',
    },
  }}
  height={400}
>
  <LineChart data={chartData}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="month" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line
      type="monotone"
      dataKey="Production"
      stroke="#F6CA11"
      activeDot={{ r: 6 }}
    />
  </LineChart>
</ResponsiveContainer>

                </Grid>
            </>  )}

            {selectedVague === "Puissance motrice" && (
            <>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par puissance</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#F6CA11" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                    
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </>
            )}

            {selectedVague === "Marques" && (
            <>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par marque</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#F6CA11" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </>
          )}
            </TabPanel>
  
            <TabPanel value="2">
            <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Nombre de client par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChartClient />
                        </Paper>
                    </Grid>    
                    <Grid item xs={12} md={6} mt={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Nombre de ventes par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChart/>
                        </Paper>
                    </Grid> 
                    </Grid> 
                    <Grid item xs={12} md={6} mt={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        repartition des articles par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChartArticle/>
                        </Paper>
                    </Grid>      
                </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  }
  
  export default RoleCheck(['Commercial'])(DashboardCommercial);
