import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import DashboardInformaticien from "./Components/Dashboards/DashboardInformaticien";
import DashboardMecanicien from "./Components/Dashboards/DashboardMecanicien";
import DashboardCommercial from "./Components/Dashboards/DashboardCommercial";
import DashboardDirecteur from "./Components/Dashboards/DashboardDirecteur";
import DashboardFinancier from "./Components/Dashboards/DashboardFinancier";
import CustomCard from "./Components/CustomCard";
import FormationComponent from "./Components/FormationComponent";
import ProductionAgricoleComponent from "./Components/ProductionAgricoleComponent";
import PredictionsComponent from "./Components/PredictionsComponent";
import RetexComponent from "./Components/RetexComponent";
import SatisfactionComponent from "./Components/SatisfactionComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import RasaChat from "./Components/ChatButton";

function App() {
  const [user, setUser] = useState(null);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const ConditionalRasaChat = () => {
    const location = useLocation();
    const excludePaths = ["/", "/signup"]; // Chemins où RasaChat ne doit pas être affiché

    return !excludePaths.includes(location.pathname) ? <RasaChat /> : null;
  };

  return (
    <Router>
      <div>
        <ConditionalRasaChat /> {/* Bouton de chat conditionnel */}
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard_director" element={<DashboardDirecteur />} />
          <Route path="/dashboard_mechanic" element={<DashboardMecanicien />} />
          <Route path="/dashboard_sales" element={<DashboardCommercial />} />
          <Route path="/dashboard_it" element={<DashboardInformaticien />} />
          <Route path="/dashboard_fin" element={<DashboardFinancier />} />
          <Route path="/intervention" element={<CustomCard />} />
          <Route path="/retex" element={<SatisfactionComponent />} />
          <Route path="/carte" element={<RetexComponent />} />
          <Route path="/chat" element={<iframe src="/chat.html" width="100%" height="600px" />} />
          <Route path="/prediction_agricole" element={<PredictionsComponent />} />
          <Route path="/training" element={<FormationComponent />} />
          <Route path="/production_agricole" element={<ProductionAgricoleComponent />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
